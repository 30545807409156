html {
  scroll-behavior: smooth;
  body {
    font-family: "PT Serif", serif;
  }
}

.loading {
  filter: blur(10px);
}

.modal-content {
  background-color: #f9f9f9;
}

.table {
  thead {
    tr > th {
      border-top: 0px;
    }
  }
}
.page {
  padding: 20px;
  background-color: #f9f9f9;

  .phone-number {
    &:hover {
      text-decoration: none;
    }
  }

  .title {
    padding-bottom: 15px;
  }

  .text {
    text-align: justify;
    & > p {
      line-height: 1.5;
      text-indent: 47px;

      widows: 3;
      orphans: 2;
    }
  }
}

.parallax-outer {
  background-color: #f9f9f9;
}

.paralax_image_container {
  overflow: hidden;
  .parallax-outer {
    transform: translate(0px, -35px);
  }

  &.container--1,
  &.container--2,
  &.container--3 {
    @media screen and (max-width: 800px) {
      .parallax-inner,
      .parallax-outer {
        transform: none !important;
      }
    }
  }

  .paralax--photo {
    width: 100%;
    height: 70vh; // this should be dynamic, right now it's fixed based on a min max offset
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (min-width: 1000px) {
      background-position: 0px calc(20% - 50px);
    }

    @media screen and (max-width: 1000px) {
      height: 65vh;
    }

    &.photo--1 {
      @media screen and (max-width: 1000px) {
        background-position: 0px -280px;
      }

      @media screen and (max-width: 800px) {
        background-position: 0px -240px;
      }

      @media screen and (max-width: 500px) {
        background-position: 0px -170px;
      }

      @media screen and (min-width: 1500px) {
        background-position: 0px -550px;
      }
    }

    &.photo--2 {
      transform: translate(0px, 0);

      @media screen and (max-width: 1000px) {
        background-position: 0px -50px;
      }

      @media screen and (min-width: 1000px) {
        background-position: 0px -50px;
      }

      @media screen and (min-width: 1500px) {
        background-position: 0px -150px;
      }

      @media screen and (max-width: 670px) {
        background-position: center;
      }
    }

    &.photo--3 {
      @media screen and (max-width: 1000px) {
        background-position: 0px -50px;
      }

      @media screen and (min-width: 1000px) {
        background-position: 0px -350px;
      }

      @media screen and (min-width: 1500px) {
        background-position: 0px -450px;
      }
    }
  }

  max-height: 300px;
}
