.navbar {
  a.navbar-brand {
    @media screen and (max-width: 320px) {
      display: none;
    }
  }
}

.page-title {
  @media screen and (max-width: 1250px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 1200px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 980px) {
    font-size: 1.15rem;
  }
}

.navbar-nav {
  .separator {
    border: 1px solid #cfbebe;
  }
  .nav-link.nav-item {
    background-color: transparent;
    transition: all 0.3s;
    font-size: 1.1rem;

    @media screen and (max-width: 1000px) {
      padding: 5px 15px;
    }
    @media screen and (min-width: 1000px) {
      padding: 2px 15px;
    }

    &:hover {
      background-color: rgb(207, 190, 190);
      color: white !important;

      @media screen and (min-width: 1000px) {
        transform: scale(1.2);
      }
    }
  }
}
