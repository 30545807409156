.react-cookie-banner {
  position: fixed !important;
  bottom: 0px;

  transition: all 0.7s ease-out;
  opacity: 1;
  &.hide {
    opacity: 0;
  }

  @media screen and (max-width: 1000px) {
    height: 80px !important;
  }

  @media screen and (max-width: 500px) {
    height: 150px !important;
  }
}
