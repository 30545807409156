#responsive-footer-nav.footer {
    text-align: center;
    width: 100%;
    position: static;
    bottom: 30px;
    display: block !important;
    z-index: 500;
    padding: 10px;

    cursor: pointer;

    a {
        color: black;
        text-decoration: none !important;
    }
}