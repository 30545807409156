.about {
  @media screen and (max-width: 800px) {
    .media {
      flex-direction: column;
    }
  }

  .signature {
    font-family: "Allura", cursive, Arial, Helvetica, sans-serif;
    font-size: 2.25em;
    margin-right: 50px;

    @media screen and (max-width: 993px) {
      position: static;
    }
  }

  .about--photo {
    align-self: auto;

    @media screen and (max-width: 800px) {
      & {
        align-self: center;
      }
    }

    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
  }

  .about--photo,
  .text {
    margin: 10px;

    @media screen and (max-width: 800px) {
      & {
        margin: 0;
      }
    }
  }

  .certs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    img {
      cursor: pointer;
      margin: 10px 0;
      border: 1px solid rgb(151, 148, 148);
      background-color: white;
    }

    @media screen and (max-width: 800px) {
      & {
        padding: 20px 0;
      }
    }
  }
}
