$easing: cubic-bezier(0.5, 0, 0.5, 1);
.home {
  height: 100vh;
  position: relative;
  overflow: hidden;

  .home_image-container--image {
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-size: cover;
    animation: zoomin 12s normal forwards ease-in;
    transition: all 0.5s ease-in-out;
    overflow: hidden;

    @media screen and (max-width: 1000px) {
      background-position: center;
    }

    /* Zoom in Keyframes */
    @-webkit-keyframes zoomin {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.2);
      }
    }
    @keyframes zoomin {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.2);
      }
    } /*End of Zoom in Keyframes */

    @keyframes bounce {
      from {
        transform: translate3d(-50%, 0, 0);
      }
      to {
        transform: translate3d(-50%, 20px, 0) scale(0.95);
      }
    }
  }

  .home--arrow-down {
    text-align: center;
    bottom: 30px;
    z-index: 50;
    color: #fff;
    width: 55px;
    position: absolute;
    left: 50%;
    cursor: pointer;

    background-color: #333;
    padding: 5px;
    border-radius: 100%;
    opacity: 0.85;

    animation: bounce 0.5s infinite alternate;
  }

  .home--quote {
    position: relative;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);

    font-size: 2em;
    font-family: "EB Garamond", serif;

    @media screen and (max-width: 1000px) {
      font-size: 1.8em;
    }

    @media screen and (max-width: 700px) {
      font-size: 1.5em;
    }

    @media screen and (max-width: 500px) {
      font-size: 1em;
    }

    .effect {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 1em;
      cursor: pointer;

      &__heading {
        font-size: 1.5em;
        line-height: 1.2;
        position: relative;
        z-index: 2;

        &:after {
          content: "";
          position: absolute;
        }
      }

      &__subheading {
        font-family: "Hindi", Avenir, sans-serif;
        font-size: 1.2em;
        line-height: 2;
        letter-spacing: 0.04em;
      }
    }

    .effect-c {
      opacity: 0.5;
      transition: opacity 0.3s $easing;

      .effect__heading:after {
        opacity: 0;
        content: "⎦";
        font-size: 4em;
        line-height: 1;
        top: calc(100% + 20px);
        left: calc(100% + 20px);
        transform: translate(-50%, 50%) scale(0.8);
        transition: opacity 0.5s $easing, transform 0.5s $easing;

        @media screen and (max-width: 1000px) {
          left: calc(100%);
        }

        @media screen and (max-width: 700px) {
          left: calc(100% - 15px);
        }
      }

      .effect__heading:before {
        position: absolute;
        opacity: 0;
        content: "⎡";
        font-size: 4em;
        line-height: 1;
        top: 20px;
        left: -20px;
        transform: translate(-50%, -150%) scale(0.8);
        transition: opacity 0.5s $easing, transform 0.5s $easing;

        @media screen and (max-width: 1000px) {
          left: 0px;
        }

        @media screen and (max-width: 700px) {
          left: 15px;
        }
      }

      .effect__subheading {
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 0.5s $easing, transform 0.5s $easing;
      }

      &:hover {
        opacity: 1;
        .effect__subheading {
          transform: translateY(0);
          opacity: 1;
        }
        .effect__heading:after,
        .effect__heading:before {
          opacity: 0.1;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }
}
